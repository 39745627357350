import CollectionDetailsSection from '../CollectionDetailsSection/CollectionDetailsSection';
import { getCookie } from '@ui/hooks/useCookie';
import search from '@ui/axios/searchSpring/search';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Root } from '@ui/types/mappings/Collections';
import { Box } from '@ui/components/core';
import { generateFilters } from '@ui/helpers/filter';
import Head from 'next/head';
import { useEffect, useMemo, useState } from 'react';
import { AdultContentModal } from '@ui/components/shared';
import { BreadcrumbLink, BreadcrumbMapping } from '@ui/types/mappings/BreadcrumbMappings.type';
import { getCollectionStaticPropsHelpers } from '@ui/nextServer';
import { BrandsList, Brands } from '@ui/types/mappings/BrandMappings.type';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { GetProducts } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import useScarabTracking from '@ui/hooks/useScarabTracking';
import useBreadcrumbs from '@ui/hooks/useBreadcrumbs';

type CollectionItem = Omit<Awaited<ReturnType<typeof getCollectionStaticPropsHelpers>>['props'], 'product'>;

export function CollectionPage(collection: CollectionItem) {
  useScarabTracking({ collectionName: collection.collectionHeading ?? '' });
  const { elevarViewItemListEvent } = useElevarDataLayer();
  const router = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const hideModalCookie = getCookie('allowAdultContent');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLink[]>([]);
  const collectionBanner = collection.collectionImage;
  const normalizeString = (str: string) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
  const { getBreadcrumbs } = useBreadcrumbs();

  // If the collection name is a brand name, hide the collection banner
  const hideCollectionBanner = useMemo(() => {
    const normalizedCollectionName = normalizeString(collection.collectionName);
    return Brands.some((brand) => normalizeString(brand.name) === normalizedCollectionName);
  }, [collection.collectionName]);

  const {
    data: collectionData,
    error,
    isLoading,
  } = useQuery({
    staleTime: 0,
    placeholderData: keepPreviousData,
    queryKey: [
      collection.collectionName,
      JSON.stringify(router.query.collections),
      router.query.page,
      JSON.stringify(router.query.sort),
      {
        filters: [],
        bgfilter: '',
        pageNumber: 1,
        sort: 'relevance=desc',
      },
    ],
    queryFn: async () => {
      const isBrandCollection = BrandsList.includes(collection.collectionName.replace(/-/g, ' ').toUpperCase());

      let searchFilters: Array<string> = [];

      if (collection.pageType === 'category') {
        searchFilters = generateFilters(router);
      } else if (isBrandCollection) {
        searchFilters = [`brand_${collection.collectionName}`];
      }

      const { data } = await search({
        ssUserId: getCookie('ssUserId'),
        ssSessionIdNamespace: getCookie('ssSessionIdNamespace'),
        pageLoadId: getCookie('pageLoadId'),
        shopper: getCookie('shopper'),
        cart: getCookie('cart'),
        lastViewed: getCookie('lastViewed'),
        bgfilter: isBrandCollection ? undefined : collection.collectionName,
        filters: searchFilters,
        pageNumber: Number(router.query.page || 1),
        sort: (router.query.sort as string) || 'relevance=desc',
      });

      if (data.results.length > 0) {
        const productsFromShopify = await GetProducts({
          first: data.results.length,
          query: data.results.map((p) => `id:${p.uid}`).join(' OR '),
          country: getCustomerCountry(),
        });

        data.results.forEach((product) => {
          const productEdges = productsFromShopify?.data?.products.edges || [];
          const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.uid);
          if (!productNode) return;

          product.price = productNode.node.priceRange.maxVariantPrice.amount;
          product.currency = productNode.node.priceRange.maxVariantPrice.currencyCode;
          product.variant_compare_at_price = productNode.node.compareAtPriceRange.maxVariantPrice.amount;

          const images = productNode.node.images.edges.map((image) => image.node.url)
          product.images = images;
          product.imageUrl = images[0];
        });
      }

      data.results = data.results.filter((product) => product.ss_sizes && product.tags);

      return {
        query: '',
        collection: collection.collectionName as string,
        facets: data.facets,
        totalPages: data.pagination.totalPages,
        totalResults: data.pagination.totalResults,
        results: data.results,
        sortingOptions: data.sorting.options,
        filters: data.filterSummary.map((filter) => (filter.filterLabel + '_' + filter.filterValue).toLowerCase()),
        page: Number(router.query.page || 1),
        sort: (router.query.sort as string) || 'relevance=desc',
      };
    },
  });

  useEffect(() => {
    if (!collectionData?.results) return;
    elevarViewItemListEvent(
      collectionData?.results.slice(0, 10).map((product, index) => ({
        brand: product.brand,
        category: collectionData.collection,
        compare_at_price: product.variant_compare_at_price,
        id: product.sku,
        list: collection.collectionName,
        name: product.name,
        price: product.price,
        position: index,
        product_id: product.id,
        quantity: product.variant_inventory_quantity,
        sku: product.sku,
        variant: product.sku.split('-').at(-1) ?? '',
        variant_id: product.variant_product_id,
      })),
    );
  }, [collectionData, collection.collectionName, elevarViewItemListEvent]);

  useEffect(() => {
    if (router.asPath === '/collections/self-love') {
      setShowPopup(true);
      return;
    }

    setShowPopup(false);
  }, [router.asPath]);

  useEffect(() => {
    const collections = router.query.collections as string[];
    const collectionPath = '/collections/' + collections[0];

    if (BreadcrumbMapping[collectionPath]) {
      sessionStorage.setItem('lastVisitedCollectionPage', collectionPath);
      setBreadcrumbs(BreadcrumbMapping[collectionPath]);
    } else if (!BreadcrumbMapping[collectionPath]) {
      sessionStorage.setItem('lastVisitedCollectionPage', collectionPath);
      setBreadcrumbs(getBreadcrumbs(collections[0], collections[collections.length - 1].split('+')));
    }
  }, [router.query]);

  return (
    <>
      {!error && (
        <Head>
          {collection.SEO.title && <title>{collection.SEO.title}</title>}
          {collection.SEO.canonical && <link rel="canonical" href={collection.SEO.canonical} />}
          {collection.SEO.meta &&
            Object.entries(collection.SEO.meta).map(([tag, content]) => (
              <meta key={tag} name={tag} content={content} />
            ))}
        </Head>
      )}
      <Box>
        <Head>
          {collection.SEO.meta &&
            Object.entries(collection.SEO.meta).map(([tag, content]) => (
              <meta key={tag} name={tag} content={content} />
            ))}
        </Head>
        {isLoading && <div className="h-screen w-auto"></div>}
        {showPopup && !hideModalCookie && <AdultContentModal />}
        <div
          style={{
            opacity: isLoading ? 0 : 1,
            transition: 'opacity 0.5s',
          }}
          className="transition-all opacity-0"
        >
          <CollectionDetailsSection
            data={collectionData ?? ({} as Root)}
            onSortChange={({ sort }) => {
              router.query.sort = sort;
              router.push(router);
            }}
            collectionBanner={hideCollectionBanner ? undefined : collectionBanner}
            breadcrumbs={breadcrumbs}
            collectionHeading={collection.collectionHeading}
            collectionTopText={collection.collectionTopText}
            collectionBottomText={collection.collectionBottomText}
            collectionMenuLinks={collection.collectionMenuLinks}
          />
        </div>
      </Box>
    </>
  );
}

export default CollectionPage;
