import { sortBy } from 'lodash';
import { NextRouter } from 'next/router';

const EXCLUDED_CATEGORY_PATHS = [
  '/collections/exclusive/brand_hello-molly/',
  '/collections/exclusive/brand_dear-emilia/',
  '/collections/exclusive/brand_sur-belle/',
  '/collections/swim/brand_hello-molly/',
  '/collections/accessories/brand_undercover-style-helpers/',
  '/collections/exclusive/brand_hello-mini/',
  '/collections/dresses/occasion_bridesmaid-&-wedding-guest/',
  '/collections/dresses/occasion_formal-&-cocktail/',
  '/collections/dresses/occasion_beach-&-holiday/',
  '/collections/dresses/occasion_brunch/',
  '/collections/dresses/occasion_date-night/',
  '/collections/dresses/occasion_festival/',
  '/collections/dresses/occasion_racing/',
  '/collections/dresses/occasion_work/',
  '/collections/skirts/length_mini/',
  '/collections/skirts/length_midi/',
  '/collections/skirts/length_maxi/',
  '/collections/skirts/color_denim/',
  '/collections/shorts/color_denim/',
  '/collections/hello-molly/trend_denim',
];

export interface Facet {
  field: string;
  label: string;
  type: string;
  multiple: string;
  collapse: number;
  facet_active: number;
  values: Value[];
}

export interface Value {
  active: boolean;
  type: string;
  value?: string;
  label: string;
  count: number;
  formattedLabel: string;
  formattedValue: string;
  low?: string;
  high?: string;
}

export const removeFiltersFromCurrentPath = (path: string) => {
  let currentPath = decodeURIComponent(path);

  const currentPathSegments = currentPath.split('/').filter(Boolean);
  const currentPathCollection = currentPathSegments.slice(0, 2);
  const currentPathFilters = currentPathSegments.slice(2).flatMap((str) => str.split('+'));
  const matchFromExludedCategoryPaths = EXCLUDED_CATEGORY_PATHS.find((excludedCategoryPath) => {
    const excludedCategorySegments = excludedCategoryPath.split('/').filter(Boolean);
    const excludedCategoryCollection = excludedCategorySegments.slice(0, 2);
    const excludedCategoryFilters = excludedCategorySegments.slice(2).flatMap((str) => str.split('+'));

    const matchesFromCurrentPathFilters = excludedCategoryFilters.filter((str) => currentPathFilters.includes(str));

    return (
      currentPathCollection.join('/') === excludedCategoryCollection.join('/') &&
      excludedCategoryFilters.length === matchesFromCurrentPathFilters.length
    );
  });

  if (matchFromExludedCategoryPaths) {
    return matchFromExludedCategoryPaths;
  } else {
    return '/' + currentPathCollection.join('/') + '/';
  }
};

export const applyFilterReplaceSlash = (filter: string): boolean =>
  filter.startsWith('color_') || filter.startsWith('size_');

const seoFriendlyFilter = (filter: string) =>
  applyFilterReplaceSlash(filter) ? filter.replaceAll(/\/|\%2F/gi, '-') : filter;

//  and we also need to remove / because this will be treated as a route so we replace / with -

export const generateFilters = (router: NextRouter) => {
  const collections = router.query.collections;
  if (collections && Array.isArray(collections) && collections.length > 1) {
    const newCollections = collections.map((collection) => {
      if (collection === 'occasion_formal-cocktail') {
        return 'occasion_formal-&-cocktail';
      } else if (collection === 'occasion_bridesmaid-wedding-guest') {
        return 'occasion_bridesmaid-&-wedding-guest';
      } else {
        if (collection.includes('+')) {
          const splitCollection = collection.split('+');
          return splitCollection
            .map((collection) => {
              if (collection === 'occasion_formal-cocktail') {
                return 'occasion_formal-&-cocktail';
              }
              if (collection === 'occasion_bridesmaid-wedding-guest') {
                return 'occasion_bridesmaid-&-wedding-guest';
              } else {
                return collection;
              }
            })
            .join('+');
        }
      }
      return collection;
    });
    return generateCollectionFilters(newCollections[1]);
  } else if (router.query.products) {
    return (router.query.filters as string)?.split('+') || [];
  } else {
    return [];
  }
};

export const generateCollectionFilters = (collectionFilter: string) => {
  return collectionFilter
    .split('+')
    .map((filter) => (applyFilterReplaceSlash(filter) ? filter.replace('-', '/') : filter));
};

export const toggleFilter = (router: NextRouter, newFilter: string) => {
  let filtersToApply = generateFilters(router).map((filter) =>
    applyFilterReplaceSlash(filter) ? filter.replace('/', '%2F') : filter,
  );

  let formattedNewFilter = applyFilterReplaceSlash(newFilter) ? newFilter.replaceAll('/', '%2F') : newFilter;

  if (filtersToApply.includes(formattedNewFilter)) {
    filtersToApply = filtersToApply.filter((filter) => filter !== formattedNewFilter);
  } else if (formattedNewFilter.startsWith('price_')) {
    filtersToApply = filtersToApply.filter((filter) => !filter.startsWith('price_'));
    filtersToApply.push(formattedNewFilter);
  } else {
    filtersToApply.push(formattedNewFilter);
  }

  return filtersToApply;
};

export const getFiltersUrl = (router: NextRouter, filtersToApply: Array<string>) => {
  const uriFilters = filtersToApply.map((filter) => {
    const formattedFilter = seoFriendlyFilter(filter);
    if (formattedFilter === 'occasion_bridesmaid-&-wedding-guest') return 'occasion_bridesmaid-wedding-guest';
    if (formattedFilter === 'occasion_formal-&-cocktail') return 'occasion_formal-cocktail';
    return formattedFilter;
  });

  if (router.query.collections) {
    return filtersToApply.length > 0
      ? `/collections/${router.query.collections[0]}/${uriFilters.join('+')}/`
      : `/collections/${router.query.collections[0]}/`;
  }

  if (router.query.products) {
    return filtersToApply.length > 0
      ? `/search/products/${router.query.products}/?filters=${uriFilters.join('+')}`
      : `/search/products/${router.query.products}/`;
  }
};

export const updateFilters = (router: NextRouter, filtersToApply: Array<string>) => {
  const redirectTo = getFiltersUrl(router, filtersToApply);

  if (router.query.collections && redirectTo) {
    const routerFn = router.query.collections.length === 1 ? router.push : router.replace;
    return routerFn(redirectTo);
  }

  if (router.query.products && redirectTo) {
    return router.replace(redirectTo);
  }
};

const LENGTH_SEQUENCE = ['MINI', 'MIDI', 'MAXI'];

const SIZE_SEQUENCE = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XS/S',
  'S/M',
  'M/L',
  'L/XL',
  'ONE-SIZE',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '12',
  '13',
  '14',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  'A',
  'B',
  'C',
  'D',
  'A-B',
  'C-D',
  '1/2YR',
  '3/4YR',
  '5/6YR',
  '7/8YR',
  '9/10YR',
  'EU 19/20',
  'EU 21',
  'EU 22/23',
  'EU 24',
  'EU 25/26',
  'EU 27',
  'EU 28/29',
  'EU 30',
];

const sortFn = (sequence: Array<string>, value?: string) => {
  const i = sequence.indexOf(value?.toUpperCase() ?? '');
  return i > -1 ? i : Infinity;
};

export interface Facet {
  field: string;
  label: string;
  type: string;
  multiple: string;
  collapse: number;
  facet_active: number;
  values: Value[];
}

export interface Value {
  active: boolean;
  type: string;
  value?: string;
  label: string;
  count: number;
  formattedLabel: string;
  formattedValue: string;
}

const SLEEVES_SEQUENCE = ['SLEEVELESS', 'SHORT-SLEEVED', 'LONG-SLEEVED'];

export const sortFacetValues = (facet: Facet) => {
  if (facet.label === 'Length') {
    return sortBy(facet.values, (fv) => sortFn(LENGTH_SEQUENCE, fv.value));
  }
  if (facet.label === 'Size') {
    return sortBy(facet.values, (fv) => sortFn(SIZE_SEQUENCE, fv.value));
  } else if (facet.label === 'Sleeves') {
    return sortBy(facet.values, (fv) => sortFn(SLEEVES_SEQUENCE, fv.value));
  } else if (['Color', 'Trend', 'Occasion', 'Brand'].includes(facet.label)) {
    return sortBy(facet.values, 'value');
  } else {
    return facet.values;
  }
};

export const filtersToUri = (filters: string[]) => [...filters].sort().join('+').replace(/\//g, '%2F');
