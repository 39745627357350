import { filtersToUri } from "@ui/helpers/filter";


const useBreadcrumbs = () => {
  const getBreadcrumbs = (collection: string, filters: string[]) => {
    const links = [{ label: collection.replaceAll('-', ' ').replaceAll('_', ' '), href: `/collections/${collection}/` }];
    if (collection === 'sale') {
      links.push({
        label: `WOMEN'S CLOTHING SALE`,
        href: `/collections/sale/`,
      });
      return links;
    }
    const categoryFromFilter = filters.find((filter) => filter.startsWith('category_'));
    if (categoryFromFilter && categoryFromFilter !== `category_${collection}`) {
      links.push({
        label: categoryFromFilter.replaceAll('-', ' ').replaceAll('_', ' '),
        href: `/collections/${collection}/${filtersToUri(filters)}/`,
      });
    } else {
      links.push({
        label: `All ${collection.replaceAll('-', ' ').replaceAll('_', ' ')}`,
        href: `/collections/${collection}/`,
      });
    }
  
    return links;
  };

  return { getBreadcrumbs };
}

export default useBreadcrumbs;